<template>
  <div class="container-app">
    <z-header/>
    <div class="container-app-bottom-con" ref="zz">
      <z-left/>
      <el-main class="container-back-color cpi-column cpi-full-width cpi-full-height cpi-j-start cpi-a-start">
        <div class="cpi-row cpi-full-width cpi-j-start cpi-a-center margin-bottom-10">
          <span class="tips-font" style="margin-left: 30px;"></span>
          <div
              style="width: 330px;">
            <el-input v-model="queryListData.title" placeholder="要搜索的标题"/>
            <!--            <el-date-picker-->
            <!--                v-model="rangeTime"-->
            <!--                type="daterange"-->
            <!--                unlink-panels-->
            <!--                range-separator="至"-->
            <!--                start-placeholder="开始日期"-->
            <!--                end-placeholder="结束日期"-->
            <!--                :shortcuts="shortcuts"-->
            <!--                @change="_timeChange"-->
            <!--            />-->
          </div>
          <el-button type="primary" class="margin-left-30" size="middle" @click="_pageChange(1)">搜索</el-button>
        </div>

        <div class="cpi-middle-side margin-bottom-10" v-loading="isLoading">
          <el-table :data="listData" stripe style="width: 100%" :height="maxHeight">
            <el-table-column prop="dId" label="爬虫编号" width="90"/>
            <el-table-column label="缩略图" width="200">
              <template v-slot="scope">
                <img class="span-img" :src="scope.row.dPic" alt=""/>
              </template>
            </el-table-column>
            <el-table-column label="标题">
              <template v-slot="scope">
                <span class="span-txt">{{scope.row.dTitle}}</span>
              </template>
            </el-table-column>
            <el-table-column label="简介">
              <template v-slot="scope">
                <span class="span-txt">{{scope.row.dIntro}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createTimeLabel" label="扫描时间" width="200"/>
            <el-table-column prop="doneTimeLabel" label="入库时间" width="200"/>
            <el-table-column fixed="right" label="操作" width="220">
              <template v-slot="scope">
                <el-button type="success" size="small" @click="_detail(scope.row)">查看原文</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination background layout="prev, pager, next" :total="queryListData.total"
                       @current-change="_pageChange" :page-size="20"/>
      </el-main>
    </div>
  </div>
</template>

<script>

import ZHeader from "@/components/zHeader"
import ZLeft from "@/components/zLeft"
import http from "@/utils/http"
import {timestampToDate} from "@/utils/timeToTranser"

export default {
  name: 'HomeView',
  components: {ZLeft, ZHeader},
  data() {
    return {
      isLoading: false,
      showEdit: false,
      listData: [],
      queryListData: {
        total: 0,
        page: 1,
        title: undefined
      }
    }
  },
  mounted() {
    this._pageChange(1)
    this._getWindowHeight()
    window.addEventListener('resize', this._getWindowHeight)
  },
  methods: {
    _pageChange(e){
      this.queryListData.page = e
      this._detailList()
    },
    _detailList(){
      http.get('detailList', this.queryListData)
      .then((res) => {
        if (res.data.status === 0) {
          for (let i = 0; i < res.data.data.list.length; i++) {
            res.data.data.list[i].createTimeLabel = timestampToDate(res.data.data.list[i].dCreateTime, 1)
            res.data.data.list[i].doneTimeLabel = timestampToDate(res.data.data.list[i].dDoneTime, 1)
          }
          this.listData = res.data.data.list
          this.queryListData.total = res.data.data.total
        }
      })
    },
    _detail(e){
      const url = e.dUrl
      if (url) {
        window.open(url, '_blank')
      }
    },
    _getWindowHeight() {
      this.maxHeight = this.$refs.zz.offsetHeight - 124
    },
    _showOrHideEdit() {
      this.showEdit = !this.showEdit
    },
    _doSpider() {
      http.post('doSpider')
          .then((res) => {
            console.log(res)
          })
    }
  }
}
</script>
<style scoped>
  .span-txt {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .span-img {
    width: 140px;
    height: 70px;
    object-fit: cover;
    border-radius: 6px;
  }
</style>
