<template>
  <div class="header-top">
    <span class="big-title">天蛛网络数据收集系统</span>
    <div class="top-right">
      <div class="top-right-child">
        <el-dropdown style="margin-right: 50px;">
          <span class="el-dropdown-link">
            <div class="top-right-con">
              <span>天蛛管理员</span>
            <el-icon class="el-icon--right" style="color: #333333">
              <arrow-down/>
            </el-icon>
            </div>
          </span>
          <template #dropdown>
<!--            <el-dropdown-menu>-->
<!--              <el-dropdown-item v-if="userInfo.roleType === 0" @click="_showOrHideRenZheng">认证信息</el-dropdown-item>-->
<!--              &lt;!&ndash;              <el-dropdown-item disabled>Action 4</el-dropdown-item>&ndash;&gt;-->
<!--              <el-dropdown-item divided @click="_logOut">退出</el-dropdown-item>-->
<!--            </el-dropdown-menu>-->
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/utils/http"
import {ElMessage} from "element-plus"

export default {
  name: "zHeader",
  data() {
    return {
      isPass: false,
      hasCheck: false,
      userInfo: {
        account: undefined,
        canLogin: undefined,
        createTime: undefined,
        isDelete: undefined,
        mobile: undefined,
        name: undefined,
        roleCode: undefined,
        roleType: undefined,
        uid: undefined
      }
    }
  },
  mounted() {
  },
  methods: {
    _userInfo() {
      http.get('userInfo')
          .then((res) => {
            if (res.data.status === 0) {
              // this.userInfo = res.data.data.userInfo
              // this.isPass = res.data.data.isPass
              // this.hasCheck = res.data.data.hasCheck
            } else if (res.data.status === 201) {
              // ElMessage.error('尚未登录')
              // this.$router.push('/login')
            }
          })
          .catch((res) => {
            console.log(res)
          })
    },
    _logOut() {
      http.post('logOut')
          .then((res) => {
            if (res.data.status === 0) {
              ElMessage.success('退出成功')
            } else {
              ElMessage.error('退出失败')
            }
            this._userInfo()
          })
          .catch((res) => {
            console.log(res)
          })
    }
  }
}
</script>

<style scoped>
.header-top {
  min-height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  box-sizing: border-box;
  border-bottom: 1px solid var(--el-border-color-light);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}

.big-title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin: 0 0 0 20px;
}

.top-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  flex: 1;
  height: 100%;
}

.top-right-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  height: 100%;
  width: auto;
  cursor: pointer;
}

.top-right-con {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  width: auto;
}

.top-ren-zheng {
  padding: 4px 3px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin-left: 10px;
  font-size: 13px;
  cursor: pointer;
}

.top-ren-zheng-select {
  background-color: #009688;
}

.top-ren-zheng-unselect {
  background-color: #e96969;
}
</style>