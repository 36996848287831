const timestampToDate = function (timestamp, type=0) {
    const date = new Date(timestamp * 1000)
    const year = date.getFullYear(); // 获取年份
    let month = date.getMonth() + 1; // 获取月份（注意月份从0开始，需要加1）
    if (month < 10) {
        month = '0' + month
    }
    let day = date.getDate(); // 获取日期
    if (day < 10) {
        day = '0' + day
    }
    let hour = date.getHours(); // 获取小时
    if (hour < 10) {
        hour = '0' + hour
    }
    let minute = date.getMinutes(); // 获取分钟
    if (minute < 10) {
        minute = '0' + minute
    }
    let second = date.getSeconds(); // 获取秒数
    if (second < 10) {
        second = '0' + second
    }
    let returnResult = year + '-' + month + '-' + day
    if (type === 1) {
        returnResult = returnResult + ' ' + hour + ':' + minute + ':' + second
    }
    return returnResult
}

const downloadFile = function (path, name) {
    const xhr = new XMLHttpRequest()
    xhr.open('get', path)
    xhr.responseType = 'blob'
    xhr.setRequestHeader('box-token', localStorage.getItem('token'))
    xhr.setRequestHeader('box-from', '1')
    xhr.send()
    xhr.onload = function () {
        if (this.status === 200 || this.status === 304) {
            // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
            if ('msSaveOrOpenBlob' in navigator) {
                navigator.msSaveOrOpenBlob(this.response, name)
                return
            }
            // const blob = new Blob([this.response], { type: xhr.getResponseHeader('Content-Type') });
            // const url = URL.createObjectURL(blob);
            const url = URL.createObjectURL(this.response)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = name
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            URL.revokeObjectURL(url)
        }
    };
}

export {
    timestampToDate, downloadFile
}