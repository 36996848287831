<template>
  <router-view/>
</template>

<style>
html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

#app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.container-app {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
}

.container-app-bottom-con {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
}

.container-back-color {
  background: #f1f1f1;
}

.cpi-column {
  display: flex;
  flex-direction: column;
}

.cpi-full-width {
  width: 100%;
}

.cpi-full-height {
  height: 100%;
}

.cpi-row {
  display: flex;
  flex-direction: row;
}

.cpi-j-center {
  justify-content: center;
}

.cpi-j-start {
  justify-content: flex-start;
}

.cpi-a-center {
  align-items: center;
}

.cpi-a-start {
  align-items: flex-start;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.cpi-right-side {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  height: 100%;
}



.cpi-middle-side {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
}

.tips-font {
  font-size: 14px;
  color: #333333;
}
</style>
