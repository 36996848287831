<template>
  <div class="left-con">
    <el-menu
        :default-active="defaultActive"
        class="el-menu-vertical-demo"
        @select="_handleSelect"
        @close="_handleClose"
    >
      <el-menu-item v-for="(item, index) in menus" :index="index.toString()" :key="index">
        <el-icon>
          <Menu v-if="index === 0"/>
          <HelpFilled v-if="index === 1"/>
          <Help v-if="index === 2"/>
          <Histogram v-if="index === 3"/>
          <User v-if="index === 4"/>
          <Document v-if="index === 5"/>
          <PieChart v-if="index === 6"/>
        </el-icon>
        <span>{{ item.name }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>

export default {
  name: "zLeft",
  data() {
    return {
      defaultActive: "0",
      menus: [
        {
          name: '数据收集',
          path: '/',
          children: []
        }
      ]
    }
  },
  mounted() {
    this._initMenu()
  },
  methods: {
    _initMenu() {
      const pathName = this.$route.path
      for (let i = 0; i < this.menus.length; i++) {
        if (this.menus[i].path === pathName) {
          this.defaultActive = i.toString()
          break
        }
      }
    },
    _handleSelect(e) {
      let pathName = undefined
      for (let i = 0; i < this.menus.length; i++) {
        if (parseInt(i) === parseInt(e)) {
          pathName = this.menus[i].path
          break
        }
      }

      if (!pathName) {
        return
      }

      this.$router.push({
        path: pathName
      })
    },
    _handleClose(e) {
      console.log(e)
    }
  }
}
</script>

<style scoped>
.left-con {
  box-shadow: 2px 5px 10px rgba(0, 0, 0, .12);
  transition: all .3s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
}
</style>